import './assets/css/reset.css'
import './App.scss';

import cookie from 'react-cookies';

import { Route, Switch } from 'react-router-dom';


// import Home from './pages/Home/Home';
// import MarketPlace from './pages/MarketPlace/MarketPlace';
// import Preorder from './pages/Preorder/Preorder';
// import RabbitDetail from './pages/RabbitDetail/RabbitDetail';
// import MyAccount from './pages/MyAccount/MyAccount';
// import HomeMusic from './pages/HomeMusic/HomeMusic';
// import Stake from './pages/Stake/Stake'
// import Account from './pages/Account/Account';

import Home2 from './pages/HomeGarbage/Home2';
import Garbage from './pages/Garbage/Garbage';


function App() {
  
  // // 先判断有无安装 MetaMask 钱包
  // if (window.ethereum) {  
  //   console.log("window.ethereum")
  //   // 先从cookie中获取账号
  //   const wallet_account = cookie.load('wallet_account');
  //   // 将账户放入全局状态
  //   if (wallet_account) {
  //     window.walletAccount = wallet_account.toLowerCase();
  //       window.ethereum.request({method:'eth_chainId'})
  //        .then(chainId => {
  //           if (chainId === '0x61') {
  //             window.walletAccount = wallet_account.toLowerCase();
  //           } else {
  //             cookie.remove('wallet_account',{path: "/"});
  //             window.walletAccount=null
  //           }
  //        });
  //   } 


  //   // 监听当账号发生变化
  //   window.ethereum.on("accountsChanged",function(accounts){
  //       // alert('切换账号！');
  //       window.location.reload(true);
  //       if(accounts[0]){
  //         cookie.save('wallet_account', accounts[0].toLowerCase(), {path: '/',maxAge: 3600});
  //       }
  //   });


  //   // 监听账号断开
  //   window.ethereum.on("disconnect",function(error){
  //       // alert('断开钱包！');
  //       cookie.remove('wallet_account',{path: "/"});
  //       // window.location.reload();
  //   });

  //   // 监听切换网络
  //   window.ethereum.on('chainChanged', function(id) {
  //     if (id === '0x61') {
  //       return;
  //     }
  //     //alert(id);
  //     //alert('网络变化！');
  //     // 清空cookie
  //     cookie.remove('wallet_account',{path: "/"});
  //     window.location.reload();
      
  //   });

  // } else {
  //     alert('请安装 metamask 钱包！');
  // }       




  return (
    <div className="App">
      

      <div className="app-container">
        <Switch>
          {/* <Route path="/marketplace" component={MarketPlace}/>
          <Route path="/preorder" component={Preorder}/>       
          <Route path="/rabbit/:address" component={RabbitDetail}/>       
          <Route path="/rabbit" component={MarketPlace}/>  
          <Route path="/myaccount" component={MyAccount}/>    
          <Route path="/stake" component={Stake}/>
          <Route path="/account" component={Account}/> */}
          <Route path="/garbage" component={Garbage}/>
          <Route path="/" component={Home2}/>
        </Switch>
      </div>
  
    </div>
  );
}

export default App;
