import React, { useEffect, useState, useRef, useCallback } from "react";
import './Garbage.scss';
import { Image, Input, Checkbox, Progress, Modal, message, List, Tooltip, Radio } from 'antd';
import headTitle from "../../imgs/headTitle.png"
import headIcon from "../../imgs/headIcon.png"
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Toast } from 'primereact/toast';
import modelbg from "../../imgs/modelbg2.png"
import cpm from "../../imgs/cpm.png"
import ApiService from "../../api/ApiService";
import { CloseCircleTwoTone, PlayCircleTwoTone, RedoOutlined, PlayCircleOutlined, FullscreenOutlined, FullscreenExitOutlined,FormOutlined } from '@ant-design/icons';
import { Chart, Dataset } from 'react-rainbow-components';
import Cookies from 'js-cookie'
import { useHistory } from "react-router-dom";
import CameraConfig from '../../json/CameraConfig.json';
import moment from "moment";
import EZUIKit from 'ezuikit-js';
import VideoPlay from './VideoPlay'
import classNames from "classnames";

// const player = new EZUIKit.EZUIPlayer('myPlayer');
moment.locale('zh-cn', {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
    weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'YYYY年MM月DD日',
        LLL: 'YYYY年MM月DD日Ah点mm分',
        LLLL: 'YYYY年MM月DD日ddddAh点mm分',
        l: 'YYYY-M-D',
        ll: 'YYYY年M月D日',
        lll: 'YYYY年M月D日 HH:mm',
        llll: 'YYYY年M月D日dddd HH:mm'
    },
    meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
    meridiemHour: function (hour, meridiem) {
        if (hour === 12) {
            hour = 0;
        }
        if (meridiem === '凌晨' || meridiem === '早上' ||
            meridiem === '上午') {
            return hour;
        } else if (meridiem === '下午' || meridiem === '晚上') {
            return hour + 12;
        } else {
            // '中午'
            return hour >= 11 ? hour : hour + 12;
        }
    },
    meridiem: function (hour, minute, isLower) {
        const hm = hour * 100 + minute;
        if (hm < 600) {
            return '凌晨';
        } else if (hm < 900) {
            return '早上';
        } else if (hm < 1130) {
            return '上午';
        } else if (hm < 1230) {
            return '中午';
        } else if (hm < 1800) {
            return '下午';
        } else {
            return '晚上';
        }
    },
    calendar: {
        sameDay: '[今天]LT',
        nextDay: '[明天]LT',
        nextWeek: '[下]ddddLT',
        lastDay: '[昨天]LT',
        lastWeek: '[上]ddddLT',
        sameElse: 'L'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
    ordinal: function (number, period) {
        switch (period) {
            case 'd':
            case 'D':
            case 'DDD':
                return number + '日';
            case 'M':
                return number + '月';
            case 'w':
            case 'W':
                return number + '周';
            default:
                return number;
        }
    },
    relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        ss: '%d秒',
        m: '1分钟',
        mm: '%d分钟',
        h: '1小时',
        hh: '%d小时',
        d: '1天',
        dd: '%d天',
        M: '1个月',
        MM: '%d个月',
        y: '1年',
        yy: '%d年'
    },
    week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
});
const data = [
    {
        "key": "0",
        "label": "Documents",
        "data": "Documents Folder",
        "icon": "pi pi-fw pi-inbox",
        "children": [{
            "key": "0-0",
            "label": "Work",
            "data": "Work Folder",
            "icon": "pi pi-fw pi-cog",
            "children": [{ "key": "0-0-0", "label": "Expenses.doc", "icon": "pi pi-fw pi-file", "data": "Expenses Document" }, { "key": "0-0-1", "label": "Resume.doc", "icon": "pi pi-fw pi-file", "data": "Resume Document" }]
        },
        {
            "key": "0-1",
            "label": "Home",
            "data": "Home Folder",
            "icon": "pi pi-fw pi-home",
            "children": [{ "key": "0-1-0", "label": "Invoices.txt", "icon": "pi pi-fw pi-file", "data": "Invoices for this month" }]
        }]
    },
    {
        "key": "1",
        "label": "Events",
        "data": "Events Folder",
        "icon": "pi pi-fw pi-calendar",
        "children": [
            { "key": "1-0", "label": "Meeting", "icon": "pi pi-fw pi-calendar-plus", "data": "Meeting" },
            { "key": "1-1", "label": "Product Launch", "icon": "pi pi-fw pi-calendar-plus", "data": "Product Launch" },
            { "key": "1-2", "label": "Report Review", "icon": "pi pi-fw pi-calendar-plus", "data": "Report Review" }]
    },
    {
        "key": "2",
        "label": "Movies",
        "data": "Movies Folder",
        "icon": "pi pi-fw pi-star-fill",
        "children": [{
            "key": "2-0",
            "icon": "pi pi-fw pi-star-fill",
            "label": "Al Pacino",
            "data": "Pacino Movies",
            "children": [{ "key": "2-0-0", "label": "Scarface", "icon": "pi pi-fw pi-video", "data": "Scarface Movie" }, { "key": "2-0-1", "label": "Serpico", "icon": "pi pi-fw pi-video", "data": "Serpico Movie" }]
        },
        {
            "key": "2-1",
            "label": "Robert De Niro",
            "icon": "pi pi-fw pi-star-fill",
            "data": "De Niro Movies",
            "children": [{ "key": "2-1-0", "label": "Goodfellas", "icon": "pi pi-fw pi-video", "data": "Goodfellas Movie" }, { "key": "2-1-1", "label": "Untouchables", "icon": "pi pi-fw pi-video", "data": "Untouchables Movie" }]
        }]
    }
]


const Garbage = () => {
    const [nodes, setNodes] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [selectedKey, setSelectedKey] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [eventListData, setEventListData] = useState([]);
    const [rankData, setRankData] = useState([]);
    const [url, setUrl] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [eventAddress, setEventAddress] = useState("");
    const [selectEventItem, setSelectEventItem] = useState(null);
    const [selectNode, setSelectNode] = useState(null);
    const [onlineCount, setOnlineCount] = useState(0);
    const [offlineCount, setOfflineCount] = useState(0);
    const [workPercent, setWorkPercent] = useState(0);
    const [alleventCount, setAlleventCount] = useState(0);
    const [finishCount,setFinishCount]= useState(0);
    const [isHd, setIsHd] = useState("hd.");
    const [playDevice, setPlayDevice] = useState("");
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [playback, setPlayback] = useState(false);
    const [nodeEventSelect, setNodeEventSelect] = useState(null);
    const labels = ['未完成任务', '完成任务'];
    const [nowTime,setNowTime] = useState("");
    const [currentPage,setCurrentPage] = useState(1);
    const dataset = [
        {
            value: alleventCount,
            color: '#BC3F4A',
        },
        {
            value: finishCount,
            color: '#2DB6D9',
        },
    ];

    const [datapi,setDatapai] = useState([110,0])
    const colorsArr = ['#BC3F4A','#2DB6D9']

    const renderDataset =() =>{
        const data = [];
        const colors = [];
        dataset.forEach(set => {
            data.push(set.value);
            colors.push(set.color);
        });
        return <Dataset title="Data" values={data} backgroundColor={colors} fill={false} className="chartjs-render-monitor"/>;
    }

    const toast = useRef(null);
    const history = useHistory();


    function callback() {
        // 可以调其他函数
        // 可以调接口
        fetchGameData()
        fetchRank()
        fetchAllEventCount()
    }


    useEffect(() => {
        (savecallback).current = callback;
    }, [nodeEventSelect]);

    const savecallback = useRef(null);

    useEffect(() => {
        // console.log("json$json", CameraConfig)

        // var arrData = []
        // for (let i = 0; i < CameraConfig.length; i++) {
        //     var node = CameraConfig[i]
        //     var obj = new Object
        //     obj["key"] = node.id;
        //     obj["label"] = node.StreetName;
        //     let addressArr = node.Address.split(",");
        //     let SerialNumberArr = node.SerialNumber.split(",");
        //     let SecondDirectoryArr = node.SecondDirectory.split(",");
        //     var childArr = []
        //     for (let j = 0; j < addressArr.length; j++) {
        //         var objCam = new Object
        //         objCam["key"] = node.PrimaryDirectory + obj.key + SecondDirectoryArr[j];
        //         objCam["label"] = addressArr[j];
        //         objCam["data"] = "";
        //         objCam["deviceSerial"] = SerialNumberArr[j];
        //         objCam["icon"] = "pi pi-fw pi-home"
        //         childArr.push(objCam)
        //     }
        //     obj["children"] = childArr;
        //     arrData.push(obj)
        // }
        // console.log("json", arrData)
        var state = Cookies.get("GarbageLogin")
        console.log("login-ss",state)
        if (!state) {
            console.log("login-ss")
            history.push("/");
            return
        }

        
        fetchGameData()
        fetchRank()
        fetchAllEventCount()
        const id = setInterval(() => { (savecallback).current() }, 1000 * 60 * 60);
        const timeId = setInterval(()=>{setNowTime(moment(new Date().getTime()).format('HH时mm分ss秒'))})
        window.onresize = function () {
            var isFull = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
            if (isFull) {
                console.log('全屏')
                setIsFullscreen(true)
            } else {
                console.log('没有全屏')
                setIsFullscreen(false)
            }
        }
        return () => {clearInterval(id);clearInterval(timeId)};
        // return ()=>Cookies.remove("GarbageLogin");
    }, []);

    const fetchGZH = async (time,address,streetName) => {
        try {
            const response = await ApiService.setEventByGZH(time,address,streetName);
            console.log('fetchGZH', response)
            if (response.data.code == 200) {
                // setAlleventCount(response.data.body)
                fetchEventData(streetName,address)
            } else {
                message.error(response.data.return_msg)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchGetEventbyAddress = async (nodesArr) => {
        try {
            const response = await ApiService.GetEventbyAddress();
            
            if (response.data.code == 200) {
                for (let i = 0; i < nodesArr.length; i++){
                    var count = 0
                    let jwName = nodesArr[i].label
                    nodesArr[i]["eventCount"] = count
                    for (let item in response.data.body) {
                        if(item == jwName){
                            for (let j = 0; j < nodesArr[i].children.length; j++){
                                for (let item_2 in response.data.body[item]){
                                    console.log('fetchGetEventbyAddress22', item_2,nodesArr[i].children[j].label)
                                    if(item_2 == nodesArr[i].children[j].label){
                                        nodesArr[i].children[j]["eventCount"] = response.data.body[item][item_2]
                                        count = count + response.data.body[item][item_2]
                                        break
                                    }
                                }
                            }
                            nodesArr[i]["eventCount"] = count
                            break
                        }
                        // var obj = {}
                        // obj["name"] = item
                        // obj["count"] = response.data.body[item]
                        // arr.push(
                        //     obj
                        // )
                        // console.log('fetchGetEventbyAddress', item)
                    }
                }
                
                // setAlleventCount(response.data.body)
                // console.log('fetchGetEventbyAddress', nodesArr)
            } else {
                message.error(response.data.return_msg)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchAllEventCount = async () => {
        try {
            const response = await ApiService.allEventCount();
            // console.log('fetchAllEventCount', response)
            if (response.data.code == 200) {
                setFinishCount(response.data.body[0])
                setAlleventCount(response.data.body[1])
            } else {
                message.error(response.data.return_msg)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchRank = async () => {
        try {
            const response = await ApiService.rank();

            var arr = []

            if (response.data.code == 200) {
                for (let item in response.data.body) {
                    var obj = {}
                    obj["name"] = item
                    obj["count"] = response.data.body[item]
                    arr.push(
                        obj
                    )
                }
                arr.sort((a, b) => b.count - a.count);
                if(arr.length > 5 ){
                    arr = arr.slice(0,5)
                }
                setRankData(arr)
            } else {
                message.error(response.data.return_msg)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchEventData = async (StreetName, Address) => {
        setEventAddress(Address)
        try {
            const response = await ApiService.lajiEventList(StreetName, Address);
            // console.log('fetchEventData33', response)
            if (response.data.code == 200) {
                if (response.data.body) {
                    // console.log('fetchEventData22')
                    var arr = []

                    for (let item in response.data.body) {
                        // console.log('fetchEventData22', response.data.body[item])
                        arr.push(response.data.body[item])
                    }
                    for (let i = 0; i < arr.length - 1; i++) {
                        // 外层循环定义趟数
                        for (let j = 0; j < arr.length - 1 - i; j++) {
                            // 内层循环定义每一趟的交换次数
                            if (arr[j].starttime < arr[j + 1].starttime) {
                                // 内部交换2个变量的值 前一个和后面一个数组元素相比较
                                const temp = arr[j]
                                arr[j] = arr[j + 1]
                                arr[j + 1] = temp
                            }
                        }
                    }
                    console.log('fetchEventData33', arr)
                    setEventListData(arr)
                }
                else {
                    setEventListData([])
                }
            } else {
                message.error(response.data.return_msg)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchGameData = async (arrData) => {
        try {
            const response = await ApiService.lajiAppKey();
            if (response.data.code == 200) {
                // const data = response.data.return_data;
                // console.log('fetchGameData', response.data.data.accessToken)
                setAccessToken(response.data.data.accessToken)
                sxtList(response.data.data.accessToken, arrData)
                // checkWarning()
            } else {
                message.error(response.data.return_msg)
            }
        } catch (error) {
            console.log(error);
        }
    };

    var cameraTotalCount = 0
    var cameraCount = 0
    var page = 0
    var cameraArr = []
    const sxtList = async (accessToken, pageStart = 0) => {
        try {
            const response = await ApiService.sxtList(accessToken,pageStart);
            var _nodes = arrData
            if (response.data.code == 200) {
                const data = response.data.data;
                cameraTotalCount = response.data.page.total;
                cameraCount = cameraCount + response.data.data.length;
                page = page+1
                // if( cameraArr[0]){
                //     cameraArr[0].push(data)
                // }else{
                //     cameraArr.concat(data)
                // }
                cameraArr = cameraArr.concat(data)
                // console.log("cameraCount11", cameraTotalCount)
                // console.log("cameraCount22",cameraCount)
                // console.log("cameraCount33", page)
                // console.log("cameraCount44", cameraArr)

                if(cameraTotalCount > cameraCount){
                    sxtList(accessToken,page)
                    return
                }
                else{
                    

                var arrData = []
                for (let i = 0; i < CameraConfig.length; i++) {
                    var node = CameraConfig[i]
                    var obj = new Object
                    obj["key"] = node.id;
                    obj["label"] = node.StreetName;
                    obj["icon"] = "pi pi-fw pi-home"
                    let addressArr = node.Address.split(",");
                    let SerialNumberArr = node.SerialNumber.split(",");
                    let SecondDirectoryArr = node.SecondDirectory.split(",");
                    var childArr = []
                    for (let j = 0; j < addressArr.length; j++) {
                        var objCam = new Object
                        objCam["key"] = node.PrimaryDirectory + obj.key + SecondDirectoryArr[j];
                        objCam["label"] = addressArr[j];
                        // objCam["label"] = addressArr[j];
                        objCam["StreetName"] = node.StreetName;
                        objCam["icon"] = "pi pi-fw pi-trash"
                        objCam["state"] = "0"
                        for (let a = 0; a < cameraArr.length; a++) {
                            let info = cameraArr[a]
                            if (info.deviceSerial == SerialNumberArr[j]) {
                                objCam["data"] = info;
                                objCam["state"] = info.status
                                // console.log('sxtList', objCam)
                                break
                            }
                        }
                        childArr.push(objCam)
                    }
                    obj["children"] = childArr;
                    arrData.push(obj)
                }
                setNodes(arrData);

                let online = 0
                let offline = 0
                for (let index = 0; index < cameraArr.length; index++) {
                    if (cameraArr[index].status == 1) {
                        online++
                    } else {
                        offline++
                    }
                }
                let percent = ((online / (online + offline)) * 100).toFixed(1)
                setOnlineCount(online)
                setOfflineCount(offline)
                setWorkPercent(percent)
                // for(let i = 0; i < data.length; i++){
                //     let info = data[i]
                //     flag = false
                //     for(let j = 0; j < _nodes.length; j++){
                //         if(flag==true){
                //             break;
                //         }
                //         for(let k = 0; k < _nodes[j].children.length; k++){
                //             if(_nodes[j].children[k].deviceSerial == info.deviceSerial){
                //                 _nodes[j].children[k].data = info
                //                 console.log('sxtList22', _nodes[j].children[k])

                //                 console.log('sxtList333', _nodes)
                //                 flag = true
                //                 break
                //             }
                //         }
                //     }

                // }

                // console.log('sxtList22', arrData)
                // for (const key in data) {
                //     if (Object.hasOwnProperty.call(data, key)) {
                //         const value = data[key]
                //         if (urlData[key]) {
                //             urlData[key].label = value.deviceType
                //             urlData[key].children[0].label = value.deviceSerial
                //             urlData[key].children[0].name = value.deviceSerial
                //             urlData[key].children[0].url = 'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://kido123456@open.ys7.com/' + value.deviceSerial + '/1.live&autoplay=1&accessToken=' + accessToken
                //         } else {
                //             urlData.push({
                //                 label: value.deviceSerial,
                //                 name: value.deviceSerial,
                //                 url: 'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://kido123456@open.ys7.com/' + value.deviceSerial + '/1.live&autoplay=1&accessToken=' + accessToken
                //             })
                //         }
                //     }
                // }
                if (nodeEventSelect) {
                    fetchEventData(nodeEventSelect.StreetName, nodeEventSelect.label)
                } else {
                    fetchEventData(arrData[0].label, arrData[0].children[0].label)
                    setNodeEventSelect(arrData[0].children[0])
                }

                cameraTotalCount = 0
                    cameraCount = 0
                    page = 0
                    cameraArr = []
                }
                fetchGetEventbyAddress(arrData)
            } else {
                message.error(response.data.return_msg)
            }
        } catch (error) {
            console.log(error);
        }
    }


    const onNodeSelect = (node) => {

        console.log("sss", node)
        if (!node.node.children) {
            // setIsModalOpen(true);
            setNodeEventSelect(node.node)
            fetchEventData(node.node.StreetName, node.node.label)
            setCurrentPage(1)
        } else {
            // let _expandedKeys = {};

            // return
            // for (let node of nodes) {
            //     expandNode(node, _expandedKeys,node.node.key);
            // }
        }
        // toast.current.show({ severity: 'success', summary: 'Node Selected', detail: node.label, life: 3000 });
    }

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = false;

            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    }
    const onNodeUnselect = (node) => {
        toast.current.show({ severity: 'success1', summary: 'Node Unselected', detail: node.label, life: 3000 });
    }

    const handleCancel = () => {
        setIsModalOpen(false);

    };

    const handleCancelEvent = () => {
        setIsEventModalOpen(false)
    }

    const itemClick = (item) => {
        console.log("itemClick",item)
        setSelectEventItem(item)
        setIsEventModalOpen(true)
    }

    function treePlayerIcon(node) {
        if (!node.children && node.data) {
            if(node.state == 1){
                return <div style={{ textAlign: "center" }}>
                {
                    node.eventCount ?
                    <span style={{color:"#ED4828",padding:"0 10px",fontSize: "18px"}}>{node.eventCount}起</span>
                    :null
                }
                <span onClick={(e) => treePlayerClick(e, node)}><Tooltip title="预览" color={"#2db7f5"}><PlayCircleTwoTone style={{ fontSize: "20px" }} /></Tooltip></span>
            </div>
            }else{
                return <div style={{ textAlign: "center" }}>
                {
                    node.eventCount ?
                    <span style={{color:"#ED4828",padding:"0 10px",fontSize: "18px"}}>{node.eventCount}起</span>
                    :null
                }
                <span ><Tooltip title="设备离线" color={"gray"}><PlayCircleOutlined style={{ fontSize: "20px" }} /></Tooltip></span>
            </div>
            }
            
        } else if (!node.children && !node.data) {
            return <div style={{ textAlign: "center" }}>
                {
                    node.eventCount ?
                    <span style={{color:"#ED4828",padding:"0 10px",fontSize: "18px"}}>{node.eventCount}起</span>
                    :null
                }
                <span ><Tooltip title="设备离线" color={"gray"}><PlayCircleOutlined style={{ fontSize: "20px" }} /></Tooltip></span>
            </div>
        } else {
            return <> {
                node.eventCount > 0 ?
                <span style={{color:"#ED4828",padding:"0 10px",fontSize: "18px"}}>共{node.eventCount}起</span>
                :null
            }
            </>
        }
    }

    const nodeTemplate = (node, options) => {
        // console.log("nodeTemplate", node, options)
        let label = node.label;
        return (
            <div className="item-player">
                <span className={options.className}>
                    {label}
                </span>
                {
                    // !node.children && node.data ? <span onClick={(e) => treePlayerClick(e, node)}><Tooltip title="预览" color={"#2db7f5"}><PlayCircleTwoTone style={{ fontSize: "20px" }} /></Tooltip></span> : 
                    // {
                    //     !node.children && !node.data ?<div></div>:null
                    // }
                    treePlayerIcon(node)
                }
            </div>
        )
    }

    const treePlayerClick = async (e, node) => {
        e.stopPropagation();

        setPlayDevice(node.data.deviceSerial)
        // var urlM = 'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/' + node.data.deviceSerial + '/1.'+isHd+'live&autoplay=1&accessToken=' + accessToken
        var urlM = 'ezopen://kido123456@open.ys7.com/' + node.data.deviceSerial + '/1.' + isHd + 'live'
        // ezopen://kido123456@open.ys7.com/K79246488/1.hd.live
        setPlayback(false)
        setUrl(urlM)
        setIsModalOpen(true);
        console.log("treePlayerClick", e, node.data.deviceSerial,)

    }

    const preClick = () => {
        setPlayback(false)
        setPlayDevice(selectEventItem.device)
        // var urlM = 'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/' + selectEventItem.device + '/1.'+isHd+'live&autoplay=1&accessToken=' + accessToken
        var urlM = 'ezopen://kido123456@open.ys7.com/' + selectEventItem.device + '/1.' + isHd + 'live'
        setUrl(urlM)
        setIsModalOpen(true);
    }

    const formatTime = (time) => {
        var year = String(time).substring(0, 8)
        var date = String(time).substring(8, 14)
        var yearStr = year.match(/(\d{4})(\d{2})(\d{2})/).filter((item, index) => index > 0).join('/')
        var dateStr = date.match(/(\d{2})(\d{2})(\d{2})/).filter((item, index) => index > 0).join(':')
        var fullStr = yearStr + " " + dateStr
        return fullStr
    }

    const changeTime = (time, begin) => {
        var year = String(time).substring(0, 8)
        var date = String(time).substring(8, 14)
        var yearStr = year.match(/(\d{4})(\d{2})(\d{2})/).filter((item, index) => index > 0).join('-')
        var dateStr = date.match(/(\d{2})(\d{2})(\d{2})/).filter((item, index) => index > 0).join(':')
        var fullStr = yearStr + " " + dateStr
        fullStr.substring(0, 19)
        var time = new Date(fullStr).getTime()
        // console.log("changeTime", time)
        if (begin == true) {
            return timestampToTime((Number(time) - 1000 * 10))
        } else {
            return timestampToTime((Number(time) + 1000 * 20))
        }

        // var _time = time.match(/(\d{4})(\d{2})(\d{2})/) .filter((item,index) => index > 0) .join('-')
        // _time = _time.match(/(\d{10})(\d{2})(\d{2})(\d{2})/) .filter((item,index) => index > 0) .join(':');
        // return _time
    }

    const timestampToTime = (timestamp) => {
        // 时间戳为10位需*1000，时间戳为13位不需乘1000
        var date = new Date(timestamp);
        var Y = date.getFullYear();
        var M =
            (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1);
        var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
        var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s + "000";
    }

    const backClick = () => {
        setPlayback(true)
        let beginTime = changeTime(selectEventItem.starttime, true)
        let endTime = changeTime(selectEventItem.starttime, false)
        // let asd = changeTime(beginTime)
        console.log("backClick", endTime)
        var urlM = 'ezopen://kido123456@open.ys7.com/' + selectEventItem.device + '/1.rec?begin=' + beginTime + '&end=' + endTime //+ '&autoplay=1&accessToken=' + accessToken
        // var urlM ='ezopen://kido123456@open.ys7.com/'+node.data.deviceSerial+'/1.hd.live'
        // ezopen://open.ys7.com/L01746809/1.rec?begin=20230214000000&end=20230215235959
        setUrl(urlM)
        setIsModalOpen(true);
    }

    const hdChange = (e) => {
        console.log("hdChange", e.target.value)
        var value = e.target.value
        // var urlM = 'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/' + playDevice + '/1.'+value+'live&autoplay=1&accessToken=' + accessToken
        var urlM = 'ezopen://kido123456@open.ys7.com/' + playDevice + '/1.' + value + 'live'
        setUrl(urlM)
        setIsHd(value)
    }

    const html = document.querySelector('html');
    const changeFullScreenClick = () => {
        if (!isFullscreen) {
            html.requestFullscreen()
        } else {
            document.exitFullscreen();
        }
    }

    const chuliClick = (e, node)=>{
        e.stopPropagation();
        console.log("sdsd",nodeEventSelect)
        fetchGZH(node.starttime,eventAddress,nodeEventSelect.StreetName)
    }

    return (
        <div id="myGarbage">
            <div>
                <div className="head">
                    <div>
                        <Image src={headTitle} preview={false} />
                    </div>
                    <div className="head-right">
                        <div style={{ margin: "auto" }}>{moment().format('ll')}&nbsp;{moment().format('dddd')}&nbsp;{nowTime}</div>
                        <span className="screen-change" onClick={changeFullScreenClick}>
                            {
                                isFullscreen ?
                                    <Tooltip title="退出全屏" color={"#2db7f5"}>
                                        <FullscreenExitOutlined />
                                    </Tooltip>
                                    :
                                    <Tooltip title="全屏" color={"#2db7f5"}>
                                        <FullscreenOutlined />
                                    </Tooltip>
                            }

                        </span>
                        {/* <div className="head-icon">
                            <Image src={headIcon} preview={false} width="100%"/>
                        </div> */}
                    </div>
                </div>
                <div className="silder-left-c">
                    <div className="silder-left">
                        <Toast ref={toast} />
                        <Tree value={nodes} selectionMode="single"
                            selectionKeys={selectedKey} onSelectionChange={e => setSelectedKey(e.value)}
                            onSelect={onNodeSelect} onUnselect={onNodeUnselect}
                            // expandedKeys={expandedKeys}
                            // onToggle={(e) => (setExpandedKeys(e.value),console.log("ass",e.value))}
                            nodeTemplate={nodeTemplate}
                        />
                    </div>
                    <div className="list-m">
                        <div className="list-title">{eventAddress}</div>
                        <div className="test">
                            <div className="test2">
                                <List
                                    itemLayout="vertical"
                                    // size="large"
                                    dataSource={eventListData}
                                    pagination={{
                                        onChange: (page) => {
                                            setCurrentPage(page)
                                        },
                                        pageSize: 10,
                                        hideOnSinglePage: true,
                                        showQuickJumper: true,
                                        current:currentPage
                                        // simple:true
                                    }}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div className="list-item list-item-hover" >
                                                <div>
                                                    <Image
                                                        width={70}
                                                        alt="logo"
                                                        src={item.image}
                                                    />
                                                </div>
                                                <div className="list-item" onClick={() => itemClick(item)}>
                                                    <div>{eventAddress}</div>
                                                    <div>{formatTime(item.starttime)}</div>
                                                    {
                                                        item.state == 0 ?
                                                            <div style={{ color: "#FF4E50" }}>待处理<span style={{marginLeft:"10px"}}><Tooltip title="手动处理"><FormOutlined onClick={(e)=>chuliClick(e,item)}/></Tooltip></span></div> :
                                                            <div style={{ color: "green" }}>已处理</div>
                                                    }
                                                </div>

                                                {/* <div>ssss</div> */}
                                            </div>
                                        </List.Item>
                                    )}
                                >

                                </List>
                            </div>
                        </div>
                    </div>
                    <div className="silder-right">
                        <div className="silder-right-3">
                            {
                                rankData.map((item, index) => (
                                    <div className="table-re">
                                        <div>
                                            <div className="table-re-item">
                                                <span className={classNames("table-index", { "table-index-gray": index > 2 })}>{index + 1}</span>
                                                <span className="table-re-item" style={{ padding: "0 10px" }}>{item.name}</span>
                                            </div>

                                        </div>
                                        <div className="table-re-item" style={{ padding: "0 10px", color: "#ED4827" }}>{item.count} 起</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="silder-right-1">
                            <div className="silder-right-2_1">
                                <div className="silder-right-2_1_1">
                                    <Progress
                                        type="circle"
                                        // strokeColor={{
                                        //     '0%': '#108ee9',
                                        //     '25%':'red',
                                        //     '50%':'yellow',
                                        //     '100%': '#87d068',
                                        // }}
                                        percent={workPercent}
                                        format={percent => <div style={{ color: "#87d068" }}>{percent} % </div>}
                                    // trailColor="red"
                                    />
                                    <div className="sbswz">
                                        <div className="sbs"><span style={{ color: "#12CF1E", fontSize: "30px", marginRight: "5px" }}>•</span> 正常在线设备数：<span style={{ color: "#12CF1E", fontWeight: "600", fontSize: "22px" }}>{onlineCount}</span></div>
                                        <div className="sbs"><span style={{ color: "#ED4827", fontSize: "30px", marginRight: "5px" }}>•</span> 异常离线设备数：<span style={{ color: "#ED4827", fontWeight: "600", fontSize: "22px" }}>{offlineCount}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="silder-right-2">
                            <div className="pie-s">
                                <Chart labels={labels} type="pie" showLegend={false} className="charts">
                                    {renderDataset()}
                                </Chart>
                            </div>
                            <div className="pis-sj">
                                <div className="sj-item">{finishCount}</div>
                                <div className="sj-item-n">{alleventCount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title={null} visible={isModalOpen} onCancel={handleCancel} footer={null}
                className="body-s"
                width={"1337px"}
                closeIcon={<CloseCircleTwoTone style={{ fontSize: "32px" }} />}
                destroyOnClose={true}
                centered
            >
                <div style={{ width: "100%", height: "100%" }}>
                    {
                        playback ? null :
                            <div className="check-style">
                                <di className="check-style-text">清晰度选择</di>
                                <Radio.Group defaultValue={isHd} buttonStyle="solid" size="small" onChange={hdChange}>
                                    <Radio.Button value="">流畅</Radio.Button>
                                    <Radio.Button value="hd.">高清</Radio.Button>
                                </Radio.Group>
                            </div>
                    }


                    {/* <iframe
                        src={url ? url : ""}
                        id="ysOpenDevice"
                        allowfullscreen="true" frameborder="0"
                        width="100%"
                        height="798px"
                    /> */}
                    <VideoPlay playerType={"YSY"} isPlay={true} url={url ? url : ""} accessToken={accessToken} playback={playback} style={{ width: "100%" }} />
                </div>

            </Modal>
            <Modal title={null} visible={isEventModalOpen} onCancel={handleCancelEvent} footer={null}
                className="body-e"
                // width={"640px"}
                closeIcon={<CloseCircleTwoTone style={{ fontSize: "32px" }} />}
                destroyOnClose={true}
                centered
            >
                <div>
                    <div className="imgst">
                        <div>
                            <div style={{ color: "#FF4E50" }} className="wzdx">事件图片:</div>
                            <Image
                                // width={"100%"}
                                alt="logo"
                                src={selectEventItem ? selectEventItem.image : ""}
                            />
                        </div>
                        {
                            selectEventItem && selectEventItem.state == 0 ? null :
                                <>
                                <div >
                                        
                                        {
                                            selectEventItem && selectEventItem.disposeimage && selectEventItem.disposeimage.length > 5 ?
                                            <div style={{ color: "rgb(41, 172, 41)" }} className="wzdx">已解决图片:</div>
                                            :
                                            <span style={{fontSize:"18px",display:"inline-block",color: "orange",padding:"10px 0 0 0",fontWeight:"700"}}>未上传已解决图片</span>
                                        }
                                        
                                    {
                                        selectEventItem && selectEventItem.disposeimage && selectEventItem.disposeimage.length > 5 ?
                                        
                                        <Image
                                        style={{maxHeight:"248px"}}
                                        // width={100}
                                        alt="logo"
                                        src={selectEventItem.disposeimage}
                                        /> :
                                        null
                                    }
                                    </div>
                                    {/* <div style={{ color: "rgb(41, 172, 41)" }} className="wzdx">已解决图片:</div>
                                    {
                                        selectEventItem && selectEventItem.disposeimage  ?
                                        <Image
                                        // width={100}
                                        alt="logo"
                                        src={selectEventItem.disposeimage}
                                        /> :
                                        <div>未上传</div>
                                    } */}
                                    
                                </>
                        }
                        {/* <div>
                                    <div style={{ color: "rgb(41, 172, 41)" }} className="wzdx">已解决图片:</div>
                                    <Image
                                        // width={100}
                                        alt="logo"
                                        src={selectEventItem ? selectEventItem.image : ""}
                                    />
                                </div> */}
                    </div>
                    
                    <div className="jdxx-m">
                        <div>地点：{selectEventItem ? eventAddress : ""}</div>
                        <div>时间：{selectEventItem ? formatTime(selectEventItem.starttime) : ""}</div>
                        <div>状态：
                            {
                                selectEventItem && selectEventItem.state == 0 ?
                                    <span style={{ color: "#FF4E50" ,fontWeight:"700" }}>待处理</span> :
                                    <span style={{ color: "lightgreen",fontWeight:"700" }}>已处理</span>
                            }
                        </div>
                        {
                             selectEventItem && selectEventItem.state == 0 ? null:
                            
                            <div>备注：<span style={{ color: "yellow" ,fontWeight:"400" }}> {selectEventItem&&selectEventItem.remarks ?selectEventItem.remarks :"无"}</span></div>
                        }
                        
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <div onClick={preClick}>
                            <PlayCircleTwoTone className="jdbfc-m" />
                            <span className="jdbf-m">预览</span>
                        </div>
                        <div onClick={backClick}>
                            <RedoOutlined className="jdbfc-m" />
                            <span className="jdbf-m">回放</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Garbage;