import React, {  useEffect, useState, useRef } from 'react';
// import { connect } from 'dva';
// import { routerRedux } from 'dva/router';
// import { Picker, ListView, TabBar, Toast, Grid } from 'antd-mobile';
// import ReactECharts from 'echarts-for-react';
// import zhJson from 'video.js/dist/lang/zh-CN.json';
// import videoJs from 'video.js';
import EZUIKit from 'ezuikit-js';
// import img, { images } from '@/utils/img';
// import 'video.js/dist/video-js.min.css';

// declare const window: Window & { config: any };

// interface Props {
//     isPlay: boolean;
//     url: string;
//     accessToken: string;
//     playerType: string;
// }

const VideoPlay = (props) => {
    const { isPlay, url, accessToken, playerType,playback } = props;
    const [ysyPlugin, setysyPlugin] = useState();
    const [player, setplayer] = useState();

    useEffect(() => {
        console.log("VideoPlay",url)
        if (url && url.includes('ezopen')) {
            playYSY(url, accessToken);
        } else if (url && url.includes('ws')) {
            onStartPlay(url);
        }
    }, [url, accessToken]);

    const onStartPlay = (playURL) => {
        if (player) {
            player.JS_Play(playURL, { playURL, mode: 1 }, player.iCurrentWndIndex).then(
                res => {
                    console.log('play sucess');
                },
                err => {
                    console.log('play err');
                }
            );
        } else {
            // @ts-ignore
            const myPlugin = new window.JSPlugin({
                szId: 'play_window', //需要英文字母开头 必填
                szBasePath: './',
                iMaxSplit: 1,
                iCurrentSplit: 1,
                oStyle: {
                    border: '#343434',
                    borderSelect: '#FFCC00',
                    background: '#000',
                },
            });
            setplayer(myPlugin);
        }
    };

    const playYSY = (url, accessToken) => {
        if (ysyPlugin && url && accessToken) {
            ysyPlugin.stop().then(() => {
                ysyPlugin.play({ url, accessToken });
            });
        } else if (url && accessToken) {
            const ysyPlugin = new EZUIKit.EZUIKitPlayer({
                id: 'ysy_window', // 准备的dom元素的id，画面就在这里面播放,
                audio:"0",
                accessToken: accessToken,
                url: url,
                template: playback ?"simple":"standard",
                // footer: ['hd', 'fullScreen'],
                width: 1230,
                height: 650,
                
            });
            setysyPlugin(ysyPlugin);
        }
    };

    return (
        <div>
            <div
                className="play_window"
                id="play_window"
                style={{ display: playerType === 'HK' ? 'block' : 'none' }}
            ></div>
            {/* 萤石云使用下面的播放器，海康威视使用上面的播放器 */}
            <div
                className="ysy_window"
                id="ysy_window"
                style={{ display: playerType === 'YSY' ? 'block' : 'none' , overflowX:"hidden"}}
            ></div>
        </div>
    );
}
export default VideoPlay;