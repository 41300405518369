import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

import './index.css';

import App from './App';


ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
    <ConfigProvider locale={zhCN}>
      <App />
      
  </ConfigProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
