import React, { useEffect, useState } from "react";
import './Home2.scss';
import { Image, Input, Checkbox, Button, message } from 'antd';
import home_title from "../../imgs/home_title.png"
import img1 from "../../imgs/img1.png"
import img2 from "../../imgs/img2.png"
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie'
import {
    EyeTwoTone,
    EyeInvisibleTwoTone
  } from '@ant-design/icons';

const optionsWithDisabled = [
    {
        label: '记住密码',
        value: 'password',
    },
    {
        label: '自动登录',
        value: 'auto',
    },
];

const loginName = "admin"
const loginPassword = "BSYjd@123"

const Home2 = () => {

    const history = useHistory();

    const [nameValue,setNameValue] = useState("")
    const [passwordValue,setPasswordValue] = useState("")

    const onChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
    };

    const loginClick = ()=>{
        if(nameValue.length <= 0){
            message.error("账户名不能为空")
            return
        }else if(passwordValue.length <= 0){
            message.error("密码不能为空")
            return
        }else if(nameValue != loginName || passwordValue != loginPassword){
            message.error("用户名或密码不正确，请确认")
            return
        }
        // console.log('loginClick',nameValue)
        // return
        Cookies.set("GarbageLogin", true, { expires: 7,path: '' })
        history.push("/garbage");
    }

    const nameValueChange =(e)=>{
        console.log('nameValueChange',e.target.value)
        setNameValue(e.target.value)
    }

    const passwordValueChange =(e)=>{
        setPasswordValue(e.target.value)
    }

    useEffect(() => {
        
        
      }, []);
    return (
        <div id="my-home2">
            <div className="c1">
                <div className="c1_1">
                    <Image src={home_title} preview={false} />
                </div>
                <div className="c1_2">
                    <div className="c1_2_1">
                        <Image src={img1} preview={false} />
                    </div>
                    <div className="c1_2_2">
                        <Input type="text" onChange={nameValueChange}/>
                    </div>
                    <div className="c1_2_1">
                        <Image src={img2} preview={false} />
                    </div>
                    <div className="c1_2_2">
                        <Input.Password type="password" onChange={passwordValueChange} className="sdsds" iconRender={(visible) => (visible ? <EyeTwoTone style={{fontSize:"20px"}}/> : <EyeInvisibleTwoTone style={{fontSize:"20px"}}/>)}/>
                    </div>
                    {/* <div className="c1_2_3">
                    <Checkbox onChange={onChange}>记住密码</Checkbox>
                    <Checkbox onChange={onChange}>自动登录</Checkbox>
                    </div> */}
                    <div className="c1_2_4">
                        <Button className="c1_2_4_1" onClick={loginClick}> </Button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home2;