import axios from "axios";
const API_BASE_URL = "https://api.wenchenst.cn:8890/api/";
const API_BASE_URL_SET = "https://api.wenchenst.cn/api/";
class ApiService {
    lajiAppKey() {
        return axios
            .post(
                `https://open.ys7.com/api/lapp/token/get?appKey=d53c6a0f52f342b4b6056fb2fc93e62a&appSecret=3446ef383e8c3025e75282f940b0320e`
            )
    }

    sxtList(accessToken,pageStart=0){
        return axios
            .post(
                `https://open.ys7.com/api/lapp/device/list?accessToken=${accessToken}&pageStart=${pageStart}&pageSize=100`
            )
    }

    lajiEventList( StreetName,Address){
        var date = new Date()
        var year = date.getFullYear()
        var month = date.getMonth() + 1 < 10 ? '0' + Number(date.getMonth() + 1) : date.getMonth() + 1
        var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var today = year + month + day
        // console.log('yy', today)
        var con = '20230206'
        return axios
            .get(
                // `http://192.168.110.123:8890/api/GetDeviceEvent?time=${con}&name=192.168.0.100`
                API_BASE_URL +
                `GetDeviceEvent?StreetName=${StreetName}&Address=${Address}&time=${""}`
            )
    }

    rank(){
        return axios
            .get(
                API_BASE_URL +
                `GetRank`
            )
    }

    allEventCount(){
        return axios
            .get(
                API_BASE_URL +
                `GetDeviceEventAllCount`
            )
    }

    GetEventbyAddress(){
        return axios
            .get(
                API_BASE_URL +
                `GetEventbyAddress`
            )
    }

    setEventByGZH(time,address,streetName){
        return axios
        .get(
            // `http://192.168.110.123:8890/api/GetDeviceEvent?time=${con}&name=192.168.0.100`
            API_BASE_URL_SET +
            `SetEventByGZH?time=${time}&address=${address}&streetName=${streetName}&DisposeImage=""`
        )
    }

}
export default new ApiService();